import i18n from 'i18next';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from 'react-i18next';
import en from './i18n/en.json';
import ru from './i18n/ru.json';
import ua from './i18n/ua.json';
import it from './i18n/it.json';
import fr from './i18n/fr.json';
import pl from './i18n/pl.json';
import de from './i18n/de.json';
import es from './i18n/es.json';
import pt from './i18n/pt.json';
import cn from './i18n/cn.json';
import vn from './i18n/vn.json';
import he from './i18n/he.json';
import jp from './i18n/jp.json';
import tr from './i18n/tr.json';
import hi from './i18n/hi.json';
import ar from './i18n/ar.json';
import bn from './i18n/bn.json';
import el from './i18n/el.json';
import tw from './i18n/tw.json';

i18n
  .use(initReactI18next)
  .use(detector)
  .use(backend)
  .init({
    debug: false,
    fallbackLng: 'en',
    saveMissing: false,
    nsSeparator: '::',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: en,
      ru: ru,
      ua: ua,
      it: it,
      fr: fr,
      pl: pl,
      de: de,
      es: es,
      pt: pt,
      cn: cn,
      vn: vn,
      he: he,
      jp: jp,
      tr: tr,
      hi: hi,
      ar: ar,
      bn: bn,
      el: el,
      tw: tw
    }
  });

export default i18n;